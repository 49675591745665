import * as Wildfire from "../../layers/static/Wildfire";

import base, { Config, StaticMapLayer } from "../../config";

import FEMA_NFHL from "../../layers/dynamic/FEMA_NFHL";
import { LatLng } from "leaflet";
import notEmpty from "../../shared/notEmpty";

const staticLayers: StaticMapLayer[] = [Wildfire.current()].filter(notEmpty);

const config: Config = {
  ...base,
  ...{
    title: "Cochise County ALERT",
    center: new LatLng(31.872806, -109.740691),
    zoom: 9,

    agencyPassList: [
      "Cochise County",
      "United States Geological Survey",
      "Pima County Regional Flood Control District",
      "Arizona Department of Water Resources",
    ],
    layers: {
      static: staticLayers,
      dynamic: [FEMA_NFHL],
      misc: [],
      lightning: false,
      cbrfc: false,
    },
  },
};

export default config;
